<template>
  <div>
    <div class="flex">
      <div class="hidden sm:flex items-center w-18 flex-shrink-0">
        <button type="button"
          class="w-12 h-12 border border-guestio-gray-50 rounded-full flex items-center justify-center shadow-xl"
          :class="{ 'bg-pink-500 border-pink-500': selected.length }" @click.prevent="toggleSelection">
          <CheckIcon v-if="selected.length" class="h-8 text-white" />
        </button>
      </div>
      <div @click="toggleSelection"
        class="cursor-pointer bg-white flex-1 border border-guestio-gray-50 flex rounded-xl px-4 py-6"
        :class="{ 'border-pink-500': selected.length }">
        <div class="flex-shrink-0 flex justify-end px-3">
          <img :src="option.icon_url" class="h-8 w-8" alt="">
        </div>
        <div class="flex-1">
          <h3 class="font-bold text-xl text-blue-800">Virtual Interview</h3>
          <p class="text-sm text-gray-500 mt-2">
            <!-- Shows can book you for an interview on their podcast, Youtube channel, etc. -->
            {{ option.description }}
          </p>
        </div>
      </div>
    </div>

    <div v-if="selected.length" class="pl-0 sm:pl-20 mt-6">
      <div class="flex items-center space-x-12">
        <label class="cursor-pointer select-none flex items-center">
          <input type="radio" class="form-radio h-6 w-6 text-pink-500" value="audio" v-model="variation">
          <span class="ml-2 flex items-center justify-between w-full">Audio</span>
        </label>

        <label class="cursor-pointer select-none flex items-center">
          <input type="radio" class="form-radio h-6 w-6 text-pink-500" value="video" v-model="variation">
          <span class="ml-2 flex items-center justify-between w-full">Video</span>
        </label>
      </div>

      <div class="mt-6">
        <template v-for="(slot, index) in selected">

          <div :key="`slot1-${index}`" class="flex items-center justify-between md:justify-start mb-5">
            <div class="flex items-center flex-col md:flex-row">
              <div>
                <ValidationProvider rules="required" :name="`Time slot duration ${index}`" v-slot="{ errors }">
                  <div class="rounded-full bg-white py-3 md:px-5 px-2 flex items-center border border-guestio-gray-50"
                    :class="{ 'border border-red-500': errors.length }">
                    <ClockIcon class="mr-3 flex-shrink-0" />
                    <select class="bg-transparent" v-model="slot.interview_duration">
                      <option v-for="duration in availableDurations" :value="duration"
                        :key="`duration-option-${duration}`"
                        :hidden="selectedDurations.includes(duration) && duration != slot.interview_duration">{{
                          duration
                        }}</option>
                    </select>
                  </div>
                </ValidationProvider>
              </div>

              <div class="hidden md:block ml-2">
                <span>minutes</span>
              </div>




            </div>

            <div class="ml-6 xl:ml-12 flex items-center relative ">
              <div>
                <ValidationProvider name="Price" :vid="`guest_price_${index}`" v-slot="{ errors }">
                  <div class="rounded-full bg-white py-3 px-5 flex items-center border border-guestio-gray-50"
                    :class="[{ 'border border-red-500': errors.length }]">
                    <DollarSignIcon class="mr-3 flex-shrink-0" />
                    <select class="bg-transparent" v-model="slot.price">
                      <option v-for="price in priceOptions" :value="price.value" :key="`price-option-${price.value}`">
                        {{ price.value != 0 ? `$${price.value}` : 'free' }}
                      </option>
                      <option value="higher_price">Higher Price</option>
                    </select>
                  </div>
                  <p class="form-error text-red-500 text-sm mt-1 font-light" v-if="errors.length">{{ errors[0] }}</p>
                </ValidationProvider>
              </div>

              <div class="ml-2 " v-if="slot.price == 'higher_price'"
                v-on:mouseenter="toggleTooltip(`tooltipRef${index}`)" ref="{{`btnRef${index}`}}"
                v-on:mouseleave="toggleTooltip(`tooltipRef${index}`, `btnRef${index}`)">
                <svg class="w-6 h-6 text-red-600" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24"
                  height="24" fill="none" viewBox="0 0 24 24">
                  <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                    d="M9.529 9.988a2.502 2.502 0 1 1 5 .191A2.441 2.441 0 0 1 12 12.582V14m-.01 3.008H12M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z" />
                </svg>
                <div ref="{{`tooltipRef${index}`}}" v-bind:class="{ 'hidden': !tooltipShow, 'block': tooltipShow }"
                  class="ml-3 block z-50 w-96 max-w-xs text-left no-underline break-words rounded-full absolute" :style="{
                    bottom: '2rem'
                  }">
                  <div
                    class="bg-white shadow-lg bg-opacity-70 w-lg text-black text-xs rounded-full p-4 border border-gray-300 ">
                    The max price for an episode is $25,000 and all prices will be rounded to the nearest $1,000.
                  </div>
                </div>
              </div>
            </div>
          </div>

          <template v-if="slot.price == 'higher_price'">
            <div class="flex items-start mb-5" :key="`slot2-${index}`">
              <div class="flex items-center">
                <div>
                  <ValidationProvider name="Price" :vid="`guest_price_${index}`" v-slot="{ errors }"
                    rules="required|min_value:5">
                    <div class="rounded-full bg-white py-3 px-5 flex items-center w-56 border border-guestio-gray-50"
                      :class="[{ 'border border-red-500': errors.length }]">
                      <DollarSignIcon class="mr-3 flex-shrink-0" />
                      <input id="price" type="number" min="5"
                        oninput="this.value = !!this.value && Math.abs(this.value) >= 0 ? Math.abs(this.value) : null"
                        class="bg-transparent w-full" v-model="slot.overPrice">
                    </div>
                    <p class="form-error text-red-500 text-sm mt-1 font-light" v-if="errors.length">{{ errors[0] }}
                    </p>
                  </ValidationProvider>
                </div>
              </div>
              <div class="ml-6 xl:ml-12 flex items-center">
                <div>
                  <ValidationProvider name="file" :vid="`guest_file_${index}`" v-slot="{ errors }">
                    <div class="rounded-full bg-white py-3 px-5 flex items-center border border-guestio-gray-50"
                      :class="[{ 'border border-red-500': errors.length }]">
                      <input type="file" @change="onFileInput($event, slot)">
                    </div>
                    <p class="form-error text-red-500 text-sm mt-1 font-light" v-if="errors.length">{{ errors[0] }}
                    </p>
                  </ValidationProvider>
                </div>
              </div>
            </div>
          </template>

          <div v-if="index > 0" class="ml-6" :key="`slot3-${index}`">
            <button @click.prevent="removeInterviewDuration(index)"
              class="rounded-full bg-gray-50 h-10 w-10 flex justify-center items-center">
              <CrossIcon />
            </button>
          </div>

        </template>

        <span class="text-red-600 text-xs w-full italic" v-if="overPriceSection">
          ( In order to charge a higher price, we need to confirm the stats of your show. Please provide a screenshot
          of your analytics )
        </span>


        <!-- <div class="ml-6 xl:ml-12 flex items-center">
            <div>
              <ValidationProvider name="Price" v-slot="{ errors }" rules="required|min_value:5">
                <div class="rounded-full bg-white py-3 px-5 flex items-center md:w-32 border border-guestio-gray-50"
                  :class="[{ 'border border-red-500': errors.length }]">
                  <DollarSignIcon class="mr-3 flex-shrink-0" />
                  <input id="price" type="number" min="5"
                    oninput="this.value = !!this.value && Math.abs(this.value) >= 0 ? Math.abs(this.value) : null"
                    class="bg-transparent w-full" v-model="slot.price">
                </div>
                <p class="form-error text-red-500 text-sm mt-1 font-light" v-if="errors.length">{{ errors[0] }}</p>
              </ValidationProvider>
            </div>
          </div> -->


      </div>

      <div class="mt-5">
        <button v-if="remainingDurations.length" @click.prevent="addInterviewDuration" type="button"
          class="text-pink-500 underline block">
          Add Additional Time Slots +
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import CheckIcon from '@/components/svgs/CheckIcon'
import ClockIcon from "@/components/svgs/ClockIcon";
import DollarSignIcon from "@/components/svgs/DollarSignIcon";
import CrossIcon from "@/components/svgs/CrossIcon";
import api from '@/api';
import Popper from "popper.js";

export default {
  name: 'VirtualInterviewOption',

  props: {
    option: Object,

    prices: {
      type: Array,
      default: () => ([])
    },
  },

  components: {
    CheckIcon,
    ClockIcon,
    DollarSignIcon,
    CrossIcon,
  },

  data() {
    return {
      selected: [],

      variation: 'video',

      defaultItem: {
        booking_option_id: this.option.id,
        price: 10,
        interview_duration: 30,
        file: null
      },
      priceOptions: [],
      overPriceSection: false,
      tooltipShow: false,
    }
  },

  watch: {
    selected: {
      deep: true,
      handler(selected) {
        this.$emit('updated', selected.map(option => ({ ...option, variation: this.variation })))
        this.overPriceSection = selected.some(option => option.price == 'higher_price')
      }
    },

    variation(variation) {
      this.$emit('updated', this.selected.map(option => ({ ...option, variation })))
    },
  },

  computed: {
    availableDurations() {
      return this.$store.getters['guest/availability/availableDurations']
    },

    selectedDurations() {
      return this.selected.map(slot => slot.interview_duration)
    },

    remainingDurations() {
      return this.availableDurations.filter(duration => !this.selectedDurations.includes(duration))
    }
  },

  methods: {
    toggleSelection() {
      if (this.selected.length) {
        this.selected = []
        return
      }

      this.selected.push({
        ...this.defaultItem
      })
    },

    addInterviewDuration() {
      if (!this.remainingDurations.length) {
        return
      }

      this.selected.push({
        interview_duration: this.remainingDurations[0] || null,
        price: 10,
        booking_option_id: this.option.id,
      })
    },

    onFileInput(event, key) {
      key.file = event.target.files[0];
      this.$emit('updated', this.selected);
    },

    freeSlot(checked, index, price) {
      if (checked) {
        this.selected[index].price = 0;
      } else {
        this.selected[index].price = price == 0 ? 10 : price;
      }
    },

    getPriceOptions() {
      api.get(`/price-options`).then((res) => {
        if (res.status == 200) {
          this.priceOptions = res.data.data;
        }
      })
    },

    removeInterviewDuration(index) {
      this.selected.splice(index, 1)
    },

    toggleTooltip(text, btnRef) {
      if (this.tooltipShow) {
        this.tooltipShow = false;
      } else {
        this.tooltipShow = true;
        new Popper(this.$refs[btnRef], this.$refs[text], {
          placement: "right"
        });
      }
    },

  },

  mounted() {
    if (this.prices && this.prices.length) {
      this.selected = [...this.prices]
      this.variation = this.prices[0].variation || 'video'
    }
    this.getPriceOptions();
  }
}
</script>
